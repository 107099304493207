import React from 'react'
import withStyles from 'react-jss'
import {Link} from 'react-router-dom'
import {Row, Col, Button} from 'reactstrap'
import {useSpring, animated} from 'react-spring'
import MarketingImage from '../../../assets/images/callToAction/marketing.svg'
import WebDevelopmentImage from '../../../assets/images/callToAction/webDevelopment.svg'



const styles = {
    callToActionWrapper:{
        
        width: '100%',
    },
    leftSide:{
        height: 'calc(100vh - 100px)',
        width: '100%',
        backgroundColor: '#04C485',
        backgroundImage: `url(${WebDevelopmentImage})`,
        backgroundRepeat: 'no-repeat',
        backgroundSize: '70% 45%',
        backgroundPosition: 'right bottom',
        display: 'block',
        '@media (max-width:  500px)': {
            height: '100vh',
            marginTop: '75px'
        }
    
    
    },
    rightSide:{
        height: 'calc(100vh - 100px)',
        width: '100%',
        background: '#8655E9',
        backgroundImage: `url(${MarketingImage})`,
        backgroundRepeat: 'no-repeat',
        backgroundSize: '70% 45%',
        backgroundPosition: 'center',
        display: 'block',
        backgroundPosition: 'left bottom',
        '@media (max-width:  500px)': {
            height: '100vh'
        }
        
    },
    ctaRow:{

    },
    leftTitle: {
        marginTop: '40px',
        textAlign:'center',
        color: 'white',
        fontWeight: '600'

    },
    rightTitle:{
        marginTop: '40px',
        textAlign: 'center',
        color: 'white',
        fontWeight: '600'
    },
    leftSubTitle:{
        marginTop: '150px',
        textAlign: 'center',
        color: 'white',
        '@media (max-width:  500px)': {
            marginTop: '50px'
        }
    },
    rightSubTitle:{
        marginTop: '120px',
        textAlign: 'center',
        color: 'white',
        '@media (max-width:  500px)': {
            marginTop: '50px'
        }
    },
    ctaBtn:{
        margin: '0px 0 0 0',
        padding: '15px',
        fontWeight: '600',
        fontSize: '1.3em',
        cursor: 'pointer',
        borderRadius: '20px',
        position: 'absolute',
        bottom: '50%',
        '@media (max-width:768px)':{
            fontSize: '1em',
            position:'relative'
        },
        margin: '0 auto'
        
    },
    ctaBtnRight:{
        background: '#04C485',
        color: 'white',
        '&:hover':{
            background: '#8655E9',
            color: '#04C485',
            border: '1px solid #04C485'
        }
    },
    ctaBtnLeft:{
        background: '#8655E9',
        color: 'white',
        '&:hover':{
            background: '#04C485',
            color: '#8655E9',
            border: '1px solid #8655E9'
        }
    },
    image :{
        maxHeight: '500px',
    }
}

const calc = (x, y) => [x - window.innerWidth / 2, y - window.innerHeight / 2]
const trans1 = (x, y) => `translate3d(${x / 10}px,${y / 10}px,0)`


const SplitCallToAction = (cssInJs) => {
    //const [isToggled, setToggle] = useState(false)
    const {classes} = cssInJs;
    const [props, set] = useSpring(() => ({ xy: [0, 0], config: { mass: 10, tension: 100, friction: 180 } }))
        

    return(
        <div className={classes.callToActionWrapper} onMouseMove={({clientX: x, clientY: y}) => set({xy: calc(x, y)})}>
            <Row className={classes.ctaRow}>
                
                    <Col md={6} xs={12} className={classes.leftSide} >
                        <animated.div style={{ transform: props.xy.interpolate(trans1)}}>
                            <h5 className={classes.leftSubTitle}>
                                SMALL BUSINESS
                            </h5>
                            <h2 className={classes.leftTitle}>
                                Custom Websites
                            </h2>
                        </animated.div>
                        <div style={{textAlign:'center'}}>
                                <Link to='/quote'>
                                    <Button className={`${classes.ctaBtn} ${classes.ctaBtnLeft}`}>
                                         QUOTE NOW
                                    </Button>
                                </Link>
                        </div>
                    </Col>
               
                

                <Col md={6} xs={12} className={classes.rightSide}>
                    <animated.div style={{ transform: props.xy.interpolate(trans1)}}>
                        <h5 className={classes.rightSubTitle}>
                            SMALL BUSINESS
                        </h5>
                        <h2 className={classes.rightTitle}>
                            Marketing/SEO<br/> Solutions 
                        </h2>
                    </animated.div>
                    <div style={{textAlign:'center'}}>
                        <Link to='/quote'>
                            <Button className={`${classes.ctaBtn} ${classes.ctaBtnRight}`}>
                                QUOTE NOW
                            </Button>
                        </Link>
                    </div>
                </Col>
            </Row>
        </div>
    )
}

export default withStyles(styles)(SplitCallToAction)