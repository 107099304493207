import React from 'react'
import withStyles from 'react-jss'
import {connect} from 'react-redux'
import {Link} from 'react-router-dom'

const styles = {}

const mapStateToProps = state => {
    return state
}
class serviceHeader extends React.Component{
    
    render(){

        return(
            <section className="iq-breadcrumb">
                <div className="iq-breadcrumb-info">
                <div className="container">
                    <div className="row">
                    <div className="col-sm-12 col-md-7 align-self-center">
                        <h2 className="iq-fw-8 mb-3">Clickficks Service</h2>
                        <nav aria-label="breadcrumb">
                        <ol className="breadcrumb">
                            <li className="breadcrumb-item"><Link to='/'>Home</Link></li>
                            <li className="breadcrumb-item">Services</li>
                            <li className="breadcrumb-item active" aria-current="page">{this.props.ServiceReducer.currentService.title}</li>
                        </ol>
                        </nav>
                    </div>
                    <div className="col-md-5">
                        <img src="https://clickficks.s3-us-west-2.amazonaws.com/images/services/mainService.jpg" className="img-fluid" alt="Page Graphic" />
                    </div>
                    </div>
                </div>
                </div>
                <div className="iq-breadcrumb-img">
                <img src="/images/breadcrumb/02.png" className="img-fluid breadcrumb-two" alt="Page Graphic" />
                </div>
                </section>
        )
    }
}

export default connect(mapStateToProps)(withStyles(styles)(serviceHeader))