import React from 'react'
import {StyleRoot} from 'radium'
import ScrollAnimation from 'react-animate-on-scroll';
import {Link} from 'react-router-dom'


class features extends React.Component{
    render(){
        return(
            <StyleRoot>
                
                <section className="features overview-block-pb5">
                    <ScrollAnimation animateIn='fadeIn'>
                    <div className="container">
                        <div className="row align-items-center h-100">
                        <div className="col-lg-6 ">
                            <p className=" text-uppercase iq-fw-3 iq-ls-3 mb-1">Why We Exist</p>
                            <h2 className="title iq-fw-8">Business, Marketing, &amp; Technology Meet</h2>
                            <p className="mt-3">
                            Small businesses want to implement technology into their workflow but find it hard to find custom solutions tailored to their needs and get stuck with a combination of software solutions that do not work together. This creates inefficiencies in the business process and results in a poor customer experience. We improve your business process and increase marketing efforts using technology.
                            </p>
                            <ul className="listing-mark mt-5">
                            <li>Automated Marketing Strategies</li>
                            <li>High Conversion Landing Pages</li>
                            <li>Custom Software Solutions</li>
                            </ul>
                            <Link className="slide-button button mt-4" to='/quote'>
                                <div className="first">Learn More</div>
                                <div className="second">Enhance My Business</div>
                            </Link>
                        </div>
                        <div className="col-lg-6 iq-rmt-40">
                            <div className="row align-items-center h-100">
                            <div className="col-sm-6">
                                <div className="iq-slolution-details iq-mt-40">
                                <div className="icon"><i className="ion-ios-cloud-download-outline" /></div>
                                <h5 className="iq-fw-9 mb-1 mt-4">Free Business Consulting</h5>
                                <p className="mb-0 mt-2">
                                    We consult with you to discover your existing business process and find ways to improve it using technology!
                                </p>
                                </div>
                                <div className="iq-slolution-details">
                                <div className="icon"><i className="ion-ios-bookmarks-outline" /></div>
                                <h5 className="iq-fw-9 mb-1 mt-4">Automated marketing</h5>
                                <p className="mb-0 mt-2">
                                    We employ a variety of automated and manual tactics to drive customers to your website once it's live!
                                </p>
                                </div>
                            </div>
                            <div className="col-sm-6 mt-4">
                                <div className="iq-slolution-details">
                                <div className="icon"><i className="ion-ios-pie-outline" /></div>
                                <h5 className="iq-fw-9 mb-1 mt-4">Custom Solutions</h5>
                                <p className="mb-0 mt-2">We craft custom solutions that cater towards your customers and provide a greater experience!</p>
                                </div>
                            </div>
                            </div>
                        </div>
                        </div>
                        
                    </div>
                    </ScrollAnimation>
                    </section>
                
            </StyleRoot>
        )
    }
}

export default features