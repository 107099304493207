import React from 'react'
import {Link} from 'react-router-dom'

class fourZeroFour extends React.Component{
    render(){
        return(
            <div>

  {/* loading End */}
  <div className="iq-pagenotfound position-relative text-center">
    <div className="container-fluid">
      <div className="row">
        <div className="col-lg-12">
          <div className="pagenotfound-info ">
           
            <h2 className="mb-3 iq-fw-8">Uh No! This Page Is Not Found</h2>
            <Link className="slide-button button" to='/'>
              <div className="first">Back to Home</div>
              <div className="second">Back to Home</div>
            </Link>
          </div>
          <img src="/images/others/9.png" className="img-fluid pagenotfound-left" alt="Theme supporting graphic" />
          <img src="/images/others/10.png" className="img-fluid pagenotfound-right" alt="Theme supporting graphic" />
        </div>
      </div>
    </div>
    
  </div>

</div>

        )
    }
}

export default fourZeroFour