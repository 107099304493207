import {SET_LOADING_STATUS} from '../../constants/loading/index'

const initialState = {
    loading: false,
    message: ''
}

const loadingReducer = (state = initialState, action) => {
    switch(action.type){
        case SET_LOADING_STATUS:
            return{ ...state,
                loading: action.loading,
                message: action.message
            }
        default: {
            return state
        }
    }
}

export default loadingReducer