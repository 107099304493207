import React from 'react'
import withStyles from 'react-jss'
import '../../../assets/css/custom/serviceCallToAction.css'
import { useSelector } from "react-redux";
import {Link} from 'react-router-dom'

const styles = {
    heroTitle: {
        
    }
}

const getServiceData = state => {
    const {title, heroText, slug} = state.ServiceReducer.currentService;
    return {
        title,
        heroText,
        slug
    }
}


const CallToAction = (props) => {
    const {classes} = props;
    const {title, heroText, slug} = useSelector(
        getServiceData
    )
    return(
        <div className={`serviceCallToActionHeader ${classes.serviceCallToActionHeader}`}>
           
                <div>
                    <div className="container">
                        <div className="banner_content">
                            <div className="row">
                                <div className="col-sm-12 col-md-7 banner_left_content">
                                    <h1 className={classes.heroTitle}>{title}</h1>
                                    <p>
                                        {heroText}
                                    </p>
                                    <Link to="/quote" className="banner_btn btn btn-success">Quote Now</Link>
                                </div>
                                <div className="col-sm-12 col-md-5">
                                    <div className="banner_right_content">
                                        <img src={`/images/service/callToAction/${slug}.svg`} className="img-responsive" alt={`${title} graphic`} />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
        </div>
    )
}

export default withStyles(styles)(CallToAction)