import React from 'react'
import {StyleRoot} from 'radium'
import OurAchivementsImage from '../../../assets/images/ourAchivements/ourHistory.jpg'
import ScrollAnimation from 'react-animate-on-scroll';

class ourAchievements extends React.Component{
    render(){
        return(
            <StyleRoot>
                <section className="iq-solutions position-relative  light-gray-bg ">
                    <ScrollAnimation animateIn='fadeIn'>
                        <div className="container">
                            <div className="row">
                            <div className="col-lg-6 col-md-12 align-self-center">
                                <p className="mt-2 text-uppercase iq-fw-3 iq-ls-3 mb-1">About us</p>
                                <h2 className="iq-fw-8 mb-2">Our History</h2>
                                <p>
                                Clickficks helps small businesses to achieve better sales and provide better digital experiences to their customers. Clickficks creates interactive business websites that enhance the customers experience and offers valuable digital content to potential customers. Websites are created with the idea of providing a better customer experience by implementing the website into the businesses process. We create custom experiences that align with the businesses strategy and allows them to onboard new customers quickly and effectively.
                                </p>
                                <div className="row mt-5">
                                <div className="col-lg-6 col-md-12">
                                    <div className="media align-items-center">
                                    <i className="ion-ios-pie-outline align-self-center" />
                                    <div className="media-body ml-3">
                                        <span className="counter">20</span>
                                        <p className="mb-0">Projects Done.</p>
                                    </div>
                                    </div>
                                </div>
                                <div className="col-lg-6 col-md-12">
                                    <div className="media align-items-center">
                                    <i className="ion-ios-heart-outline align-self-center" />
                                    <div className="media-body ml-3">
                                        <span className="counter">5</span>
                                        <p>Happy Clients.</p>
                                    </div>
                                    </div>
                                </div>
                                </div>
                            </div>
                            <div className="col-lg-6 col-md-12">
                                <img src={OurAchivementsImage} className="img-fluid" alt="Our Achivements" />
                            </div>
                            </div>
                        </div>
                    </ScrollAnimation>
                </section>
            </StyleRoot>
        )
    }
}

export default ourAchievements