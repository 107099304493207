import {SAVE_TEAM_DATA} from '../../constants/team/index'

const initalState = {

}
const teamReducer = (state=initalState, action)=> {
    switch(action.type){
        case SAVE_TEAM_DATA:
            return {
                ...state,
                team: action.teamData
            }
        default:
            return state
    }
}

export default teamReducer