import React from 'react'
import withStyles from 'react-jss'
import {connect} from 'react-redux'
import {prevFormStep} from '../../../redux/actions/quote/index'
import {StyleRoot} from 'radium'
import {FaArrowLeft} from 'react-icons/fa'

const mapStateToProps = state => {
    return state
}

const mapDispatchToProps = dispatch => ({
    prevFormStep: () => dispatch(prevFormStep())
})

const styles = {
    backBtn: {
        color: '#7C60D9',
        cursor: 'pointer'
    }
}

class backButton extends React.Component{
    render(){
        const {classes} = this.props;
        return(
            <StyleRoot>
                <span className={classes.backBtn} onClick={this.props.prevFormStep}><FaArrowLeft/>Back</span>
            </StyleRoot>
        )
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(withStyles(styles)(backButton))