import {combineReducers} from 'redux'
import LoadingReducer from '../reducers/loading/index'
import ServiceReducer from '../reducers/service/index'
import PortfolioReducer from '../reducers/portfolio/index'
import QuoteReducer from '../reducers/quote/index'
import TeamReducer from '../reducers/team/index'
import BlogReducer from '../reducers/blog/index'
import NavbarReducer from '../reducers/navbar/index'

export default combineReducers({
    LoadingReducer,
    ServiceReducer,
    PortfolioReducer,
    QuoteReducer,
    TeamReducer,
    BlogReducer,
    NavbarReducer
})