import React from 'react'
import withStyles from 'react-jss'
import {connect} from 'react-redux'
import {StyleRoot} from 'radium'
import {Row, Col, FormGroup, Label, Container} from 'reactstrap'
import {savePhone} from '../../../redux/actions/quote/index'
import ReactPhoneInput from 'react-phone-input-2'

const styles  = {
    section: {
        padding: '15px !important'
    },
    formLabel: {
        color: 'black',
        fontWeight: '600',
        fontSize: '1.2em'
    }
}

const mapStateToProps = state => {
    return state
}

const mapDispatchToProps = dispatch => ({
    savePhone: phone => dispatch(savePhone(phone))
})

class phone extends React.Component{

    handleChange = (event) => {
        this.props.savePhone(event.currentTarget.value)
    }

   

    render(){
        const {classes} = this.props;
        const AnimationStyles = require('../../../utils/animations')
        return(
            <StyleRoot>
                <Container>
                            <section className={classes.section} style={AnimationStyles.styles.zoom_in_down}>
                                <Row>
                                    <Col xs={12} md={12}>
                                       
                                            <FormGroup className={classes.nameInputFormGroup}>
                                                    <div className="form-group mb-0">
                                                        <Label>Phone</Label>
                                                        <ReactPhoneInput
                                                            defaultCountry="us"
                                                            value={this.props.QuoteReducer.phone}
                                                            onChange={(value) => this.props.savePhone(value)}
                                                            />
                                                    </div>
                                            </FormGroup> 
                                    </Col>
                                </Row> 
                            </section>
                        </Container>
            </StyleRoot>
        )
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(withStyles(styles)(phone))