import React from 'react'
import withStyles from 'react-jss'
import {connect} from 'react-redux'
import {StyleRoot} from 'radium'
import {Row, Col, FormGroup, Input, Label, Container} from 'reactstrap'
import SimpleReactValidator from 'simple-react-validator'
import {saveHasWebsite, saveWebsite, nextFormStep} from '../../../redux/actions/quote/index'
import CheckIcon from '../../../assets/images/quote/check.svg'
import XIcon from '../../..//assets/images/quote/x.svg'
import BackButton from './backButton'
import * as Scroll from 'react-scroll';

const mapStateToProps = state => {
    return state
}

const mapDispatchToProps = dispatch => ({
    saveHasWebsite: data => dispatch(saveHasWebsite(data)),
    saveWebsite: website => dispatch(saveWebsite(website)),
    nextFormStep: () => dispatch(nextFormStep())
})

const styles  = {
    icon:{
        width: '150px',
        '@media (max-width: 600px)':{
            width: '50px'
        }
    },
    wesbiteOption:{
        textAlign: 'center'
    },
    header: {
        textAlign: 'center',
        marginTop: '-58px',
        '@media (max-width: 600px)':{
            fontSize: '1em',
            marginTop: '-65px'
        }
    },
}

class website extends React.Component{


    constructor(props){
        super(props);
        
    }

    componentDidMount(){
        Scroll.animateScroll.scrollToTop()
    }

    handleChange = (event) => {
        switch(event.currentTarget.id){
            case 'yes':
                return this.props.saveHasWebsite(true)
            case 'no':
                this.props.nextFormStep()
                return this.props.saveHasWebsite(false)
            case 'website':
                return this.props.saveWebsite(event.currentTarget.value);
            case 'websiteSubmit':
                this.onWebsiteSubmit();
                return 
            default:
                alert('No case defined to handle ', event.currentTarget.id)
        }
    }

    onWebsiteSubmit = () => {
        if(this.validator.allValid()){
            this.props.nextFormStep()
        }
        else{
            this.validator.showMessages();
            this.forceUpdate()
        }
    }

    render(){
        const {classes} = this.props;
        const AnimationStyles = require('../../../utils/animations')
        return(
            <StyleRoot>
                <Container>
                            <section>
                                <Row>
                                    <Col xs={12} md={12}>
                                       
                                            <FormGroup className={classes.nameInputFormGroup}>
                                                <h4 className={classes.header} style={AnimationStyles.styles.fade_in_slow}>Do you currently have a website?</h4>
                                                    <div className="form-group mb-0">
                                                        <Row>
                                                            <Col xs={6} className={classes.wesbiteOption} onClick={this.handleChange} id='yes'>
                                                                <img src={CheckIcon} alt='Check Icon' className={classes.icon}/><br/>
                                                                Yes
                                                            </Col>
                                                            <Col xs={6} className={classes.wesbiteOption} onClick={this.handleChange} id='no'>
                                                                <img src={XIcon} alt='No Icon' className={classes.icon}/><br/>
                                                                No
                                                            </Col>
                                                        </Row>
                                                        {this.props.QuoteReducer.hasWebsite ? 
                                                            <Row>
                                                                <Col xs={12} md={12}>
                                                                    <FormGroup className={classes.nameInputFormGroup}>
                                                                        <Label className={classes.formLabel}>Please enter your your website URL</Label>
                                                                        <form className="position-relative subscribe-form">
                                                                            <div className="form-group mb-0">
                                                                                <Input type="text" className="form-control position-relative subscription-email  mb-0" id='website' value={this.props.QuoteReducer.website} onChange={this.handleChange} placeholder='' />
                                                                            </div>
                                                                            <div className="slide-button button bt-subscribe subscription-button " onClick={this.handleChange} id='websiteSubmit'>
                                                                                <div className="first">Continue</div>
                                                                                <div className="second">Continue</div>
                                                                            </div>
                                                                        </form>
                                                                        
                                                                    </FormGroup> 
                                                                </Col>
                                                            </Row> : <div/>
                                                        }
                                                    </div>
                                            </FormGroup> 
                                    </Col>
                                    <Col xs={12}>
                                        <BackButton/>
                                    </Col>
                                </Row> 
                            </section>
                        </Container>
            </StyleRoot>
        )
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(withStyles(styles)(website))