import {
    SAVE_NAME, SAVE_EMAIL, SAVE_PHONE, SAVE_HAS_WEBSITE, SAVE_WEBSITE,
    SAVE_SERVICE, REMOVE_SERVICE, SAVE_FORM_SERVICES, SAVE_FORM_SERVICE_STATUS,
    SAVE_DETAILS,
    NEXT_FORM_STEP,
    PREV_FORM_STEP
    } from '../../constants/quote/index'

const initialState = {
    name : '',
    email: '',
    phone: '',
    hasWebsite: false,
    website: 'http://',
    quoteStep: 1,
    services: [],
    formServices: [],
    formServiceStatus: false,
    details: ''
}

export const quoteReducer = (state = initialState, action) => {
    switch(action.type){
        case SAVE_NAME:
            return {
                ...state,
                name: action.name
            }
        case SAVE_EMAIL:
            return { 
                ...state,
                email: action.email
            }
        case SAVE_PHONE:
            return {
                ...state,
                phone: action.phone
            }
        case SAVE_HAS_WEBSITE:
            return { 
                ...state,
                hasWebsite: action.data
            }
        case SAVE_WEBSITE:
            return {
                ...state,
                website: action.website
            }
        case SAVE_SERVICE:
            return {
                ...state,
                 services: [...state.services, action.service]
            }
        case REMOVE_SERVICE:
            return {
                ...state,
                services: state.services.filter(service => service !== action.service)

            }
        case SAVE_FORM_SERVICES:
            return {
                ...state,
                formServices: action.formServices
            }
        case SAVE_FORM_SERVICE_STATUS:
            return {
                ...state,
                formServiceStatus: action.status
            }
        case SAVE_DETAILS:
            return {
                ...state,
                details: action.details
            }
        case NEXT_FORM_STEP:
            return {
                ...state,
                quoteStep: state.quoteStep + 1
            }
        case PREV_FORM_STEP: 
            return {
                ...state,
                quoteStep: state.quoteStep - 1
            }
        default:
            return state
    }
}

export default quoteReducer