import React, {useEffect} from 'react'
import {BrowserRouter} from 'react-router-dom'
import {connect} from 'react-redux'
import Routes from './routes'




//Redux Actions
import {savePortfolio} from '../redux/actions/portfolio/index'
import {saveServiceData} from '../redux/actions/service/index'
import {saveTeamData} from '../redux/actions/team/index'
import {saveBlogContents} from '../redux/actions/blog/index'

//Component Imports
import Navbar from './components/common/navbar'
import Loading from './components/common/loading'
import Footer from './components/common/footer'
import MobileCallButton from './components/common/callButton'




//imports for theme css
import '../assets/css/theme/all.min.css'
import '../assets/css/theme/bootstrap.min.css'
import '../assets/css/theme/font-awesome.min.css'
import '../assets/css/theme/ionicons.min.css'
import '../assets/css/theme/magnific-popup.css'
import '../assets/css/theme/mega_menu.css'
import '../assets/css/theme/menu_menu_reset.min.css'
import '../assets/css/theme/responsive.css'
import '../assets/css/theme/style.css'
import '../assets/css/theme/typography.css'
import '../assets/css/theme/wow.css'
import Axios from 'axios';
import ScrollToTop from './components/router/scrollToTop'


const mapStateToProps = state => {
    return state
}

const mapDispatchToProps = dispatch => ({
    savePortfolio: data => dispatch(savePortfolio(data)),
    saveServiceData: data => dispatch(saveServiceData(data)),
    saveTeamData: data => dispatch(saveTeamData(data)),
    saveBlogContents: data => dispatch(saveBlogContents(data))
})

const Index = (props) => {

    useEffect(()=>{
        fetchPortfolioData().then(portfolioData=>{
            props.savePortfolio(portfolioData)
        })
        .catch(err=> {
            console.log(err)
        })
        loadServicesData()
        loadTeamData()
        loadBlogData()
    }, [])

    const fetchPortfolioData = () => {
        return(
            new Promise((resolve, reject)=>{
                Axios({
                    url: 'https://api.legacy.clickficks.com/portfolio',
                    method: 'get'
                })
                .then(res=> {
                    resolve(res.data)
                })
                .catch(err=> {
                    console.log(err);
                    reject()
                })
            })
        )
    }
    const loadServicesData = () => {
        return Axios({
            method: 'get',
            url: `https://api.legacy.clickficks.com/service/`
        })
        .then(res=>{
            var data = res.data;
            var promiseArray = [];
            data.forEach(item=>{
                promiseArray.push(
                    parseList(String(item.section2List))
                )
            })

            Promise.all(promiseArray).then(section2Lists=>{
               for(var i=0; i < data.length; i++){
                   data[i].section2List = section2Lists[i]
               }
               props.saveServiceData(data)
            })
        }) 
    }
    const loadTeamData = () => {
        return(
            Axios({
                method:'get',
                url: 'https://api.legacy.clickficks.com/team'
            })
            .then(res=>{
                props.saveTeamData(res.data)
            })
            .catch(err=>{
                console.log(err)
            })
        )
    }
    const parseList = (unparsedList) =>{
        return(
            new Promise((resolve, reject)=>{
                var unparsedArray = unparsedList.split(',')
                var parsedList = [];
                unparsedArray.forEach(item=>{
                    parsedList.push(item)
                })
                resolve(parsedList)
            })
        )       
    }
    const loadBlogData = () => {
        return(
            Axios({
                method: 'get',
                url: 'https://api.legacy.clickficks.com/blog/getBlogPosts'
            })
            .then(res=> {
                props.saveBlogContents(res.data)
            })
            
        )
    }

    

    
        return(
            <div>
                    <BrowserRouter>
                        <Navbar/>
                        <ScrollToTop>
                            <Routes/>
                        </ScrollToTop>
                        <Footer/>
                        <MobileCallButton/>
                    </BrowserRouter> 
            </div>
        )
    }


export default connect(mapStateToProps, mapDispatchToProps)(Index)