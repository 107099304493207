import React from 'react'
import withStyles from 'react-jss'
import {FaPhone} from 'react-icons/fa'
import {IconContext} from 'react-icons'


const styles = {
    callButton: {
        background: '#04C485',
        width: '50px',
        height: '50px',
        position: 'fixed',
        bottom: '20px',
        right: '10px',
        color: 'white',
        borderRadius: '50%',
        textAlign: 'center',
        zIndex: '20',
        '@media (min-width: 768px)':{
            display: 'none !important'
        }
    },
    icon: {
        fontSize: '1.3em',
        position: 'relative',
        top: '8px'
    }
}
function btnClick() {
    window.location = 'tel:7132692011'
}

const CallButton = (props) => {
    const {classes} = props;
    
    return(
        <div className={classes.callButton} onClick={btnClick}>
                <IconContext.Provider value={{className: classes.icon}}>
                    <FaPhone/>
                </IconContext.Provider>
        </div>
    )
}

export default withStyles(styles)(CallButton)