import React from 'react'
import {StyleRoot} from 'radium'
import {connect} from 'react-redux'
import withStyles from 'react-jss'
import {Link, withRouter} from 'react-router-dom'

const styles = {

}

const mapStateToProps = state => {
    return state
}

class otherServices extends React.Component{
    constructor(props){
        super(props);
        this.state = {
            otherServices: []
        }
    }

    componentDidMount (){
        this.loadComponentData()
    }

    componentDidUpdate(prevProps, prevState){
        console.log('Services component updated', prevProps, prevState);
        if(prevProps.location.pathname !== this.props.location.pathname){
            //path changed
            var slug = String(prevProps.location.pathname).slice(9);
            this.loadComponentData(slug)

            console.log('Old history', prevProps.history)
            console.log('Component history', this.props.history)
        }
        
        
    }
    loadComponentData = (slug) => {
        this.findOtherServices(slug).then(otherServices=>{
            this.filterDesc(otherServices).then(filteredDesc=>{
                this.setState({otherServices: filteredDesc})
            })
        })
    }

    findOtherServices = (slug) => {
        return(
            new Promise((resolve, reject)=>{
                //find the current service
                var otherServices = [];
                var currentService = slug ? slug : typeof(this.props.ServiceReducer.currentService) != 'undefined' ? this.props.ServiceReducer.currentService.slug : ''
                console.log('Slug was equal to ', slug)
                console.log('findOtherServices ran with prop ', currentService)
                //find other services

                for(var i=0; i < this.props.ServiceReducer.services.length; i++){
                    if(this.props.ServiceReducer.services[i].slug !== currentService){
                        if(otherServices.length < 4){
                            otherServices.push(
                                new Promise((resolve, reject)=>{
                                    return resolve(this.props.ServiceReducer.services[i])
                                })
                            )
                        }
                        
                    }
                }

                Promise.all(otherServices).then(results=>{
                    resolve(results);
                    console.log('Results', results)
                })
                
            })
        )
    }
    filterDesc = (serviceList) => {
        var services = serviceList.splice(0)
        return(
            new Promise((resolve, reject)=>{
                var promises = [];
                for(var i=0; i < services.length; i++){
                    promises.push(
                        new Promise((resolve, reject)=>{
                            var desc = services[i].desc;
                            if(desc.length > 150){
                                desc = desc.substring(0,150) + '...'
                            }
                            return resolve(desc)
                        })
                    )
                }

                Promise.all(promises).then(results=>{
                    for(var i=0; i < services.length; i++){
                        services[i].desc = results[i]
                    }
                    return resolve(services)
                })
            })
        )
    }
    render(){
      
        return(
            <StyleRoot>
                <section className="iq-works position-relative pt-0">
                    <div className="container">
                        <div className="row">
                        <div className="col-sm-12 text-center">
                            <div className="section-title">
                            <p className="mb-0 text-uppercase iq-fw-5 iq-ls-2">WE OFFER MORE</p>
                            <h2 className="title iq-fw-8">Check Out Our Other Services</h2>
                            </div>
                        </div>
                        </div>
                        <div className="row">

                        {this.state.otherServices.map(s=>{
                            return(
                                <div className="col-lg-6">
                                    <div className="services purple">
                                    <div className="services-info">
                                        <div className="service-shap purple"><i className={s.icon} /></div>
                                        <h5 className="float-left mt-4">{s.title}</h5>
                                        <div className="clearfix" />
                                        <p className="mt-3 mb-0">
                                            {s.desc}
                                        </p>
                                    </div>
                                    <Link to={`/service/${s.slug}`} className="button-info">Read More <span className="float-right">. . .</span></Link>
                                    </div>
                                </div>
                            )
                        })}
                        
                        
                        
                        
                        </div>
                    </div>
                    <div className="scrollme">
                        <img src="/images/service/1.png" className="img-fluid service-one animateme" data-when="enter" data-from="0.5" data-to={0} data-translatey={500} alt="image" />
                    </div>
                </section>
            </StyleRoot>
        )
    }
}

export default connect(mapStateToProps)(withStyles(styles)(withRouter(otherServices)))