import React from 'react'
import {StyleRoot} from 'radium'
import ConsultationImage from '../../../assets/images/howWeWork/consultation.png'
import DevelopmentImage from '../../../assets/images/howWeWork/development.png'
import MarketingImage from '../../../assets/images/howWeWork/marketing.png'
import ScrollAnimation from 'react-animate-on-scroll';
import {Link} from 'react-router-dom'


class howWeWork extends React.Component{
    render(){
        return(
            <StyleRoot>
                <section className="iq-works position-relative no-bg overflow-hidden">
                    <div className="container">
                        <div className="row">
                        <div className="col-sm-12 text-center">
                            <div className="section-title">
                                <ScrollAnimation animateIn='fadeIn'>
                                    <p className="mt-2 text-uppercase iq-fw-3 iq-ls-3">WEB DESIGN/MARKETING IN ONE</p>
                                    <h2 className="title iq-fw-8">HOW DOES CLICKFICKS WORK?</h2>
                                </ScrollAnimation>
                            </div>
                        </div>
                        </div>
                        <div className="row position-relative no-gutters">
                            
                                <div className="col-lg-4">
                                    <div className="iq-work first-line">
                                        <ScrollAnimation animateIn='fadeIn'>
                                            <div className="work-content">
                                                <div className="iq-work-id  text-center">1</div>
                                                <div className="iq-work-detail text-center ">
                                                <img src={ConsultationImage} className="img-fluid mb-4" alt="Consultation Graphic" />
                                                <Link to='/quote'><h3 className="title iq-fw-8">Free Consultation</h3></Link>
                                                <p className="mt-2 mb-0">We discover your business process and perform competitor analysis to decide what should be created, who it is being created for, and how it should be presented.</p>
                                                </div>
                                                <Link className="readmore iq-fw-5" to='/quote'> QUOTE NOW <span className="float-right">. . .</span></Link>
                                            </div>
                                        </ScrollAnimation>
                                        </div>
                                    </div>
                                    <div className="col-lg-4">
                                        <div className="iq-work">
                                        <ScrollAnimation animateIn='fadeIn'>
                                            <div className="work-content">
                                                <div className="iq-work-id  text-center">2</div>
                                                <div className="iq-work-detail text-center ">
                                                <img src={DevelopmentImage} className="img-fluid mb-4" alt="Development Graphic" />
                                                <Link to='/service/web-development'><h3 className="title iq-fw-8">Development</h3></Link>
                                                <p className="mt-2 mb-0">We develop custom websites using current technologies that meet the unique needs of your business process.</p>
                                                </div>
                                                <Link className="readmore iq-fw-5" to='/service/web-development'>Learn More <span className="float-right">. . .</span></Link>
                                            </div>
                                        </ScrollAnimation>
                                        </div>
                                    </div>
                                    <div className="col-lg-4">
                                        <div className="iq-work last-line">
                                            <ScrollAnimation animateIn='fadeIn'>
                                                <div className="work-content">
                                                    <div className="iq-work-id  text-center">3</div>
                                                    <div className="iq-work-detail text-center ">
                                                    <img src={MarketingImage} className="img-fluid mb-4" alt="Marketing Graphic" />
                                                    <Link to='/service/digital-marketing'><h3 className="title iq-fw-8">Marketing</h3></Link>
                                                    <p className="mt-2 mb-0">We provide marketing services that gain you leads from your website, and provide a better experience to your existing customers. E-mail, Video, and Social Media Marketing!</p>
                                                    </div>
                                                    <Link className="readmore iq-fw-5" to='/service/digital-marketing'>Learn More <span className="float-right">. . .</span></Link>
                                                </div>
                                            </ScrollAnimation>
                                        </div>
                                    </div>
                                    </div>
                    </div>
                    </section>
            </StyleRoot>
        )
    }
}

export default howWeWork