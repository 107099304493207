import React from 'react'
import withStyles from 'react-jss'
import {StyleRoot} from 'radium'
import {connect} from 'react-redux'
import PortfolioImage from '../../assets/images/portfolio/portfolio.svg'
import {Link} from 'react-router-dom'
import {Row, Col} from 'reactstrap'
import _ from 'lodash'

const styles = {
    portfolioImage: {
        maxHeight: '250px',
        paddingBottom: '15px'
    },
    portfolioItem: {
        marginTop: '35px'
    }
}

const mapStateToProps = state => {
    return state
}


class portfolio extends React.Component{
    
    render(){
        const {classes} = this.props;
        return(
            <StyleRoot>
                <div>
                    <section className="iq-breadcrumb">
                        <div className="iq-breadcrumb-info">
                        <div className="container">
                            <div className="row">
                            <div className="col-sm-12 col-md-7 align-self-center">
                                <h2 className="iq-fw-8 mb-3">Portfolio</h2>
                                <nav aria-label="breadcrumb">
                                <ol className="breadcrumb">
                                    <li className="breadcrumb-item"><Link to='/'>Home</Link></li>
                                    <li className="breadcrumb-item active" aria-current="page">Portfolio</li>
                                </ol>
                                </nav>
                            </div>
                            <div className="col-md-5">
                                <img src={PortfolioImage} className={`img-fluid ${classes.portfolioImage}`} alt='Graphic of a card portfolio'/>
                            </div>
                            </div>
                        </div>
                        </div>
                        <div className="iq-breadcrumb-img">
                        <img src="images/breadcrumb/02.png" className="img-fluid breadcrumb-two" alt="img-fluid pr-4" />
                        </div>
                    </section>
                    {/* Breadcrumb END */}
                    <div className="main-content">
                        <div className="iq-portfolio-page overview-block-ptb overview-block-pb5">
                        <div className="container">
                            <div className="row">
                            <div className="col-lg-12">
                                <Row>
                                    {this.props.PortfolioReducer.portfolio.map(p=>{
                                        return(
                                            <Col md={6} className={classes.portfolioItem} key={`portfolioCol-${p.id}`}>
                                                <Link to={`/portfolio/${p.slug}`}>
                                                    <div className="iq-grid-item creative">
                                                        <div className="iq-portfolio">
                                                            <img 
                                                                src={_.get(p, 'images[0].imgSrc', '')} 
                                                                className="img-fluid" 
                                                                alt={_.get(p, 'images[0].imgAlt', 'Image not found')}
                                                            />
                                                            <div className="portfolio-info">
                                                                <span><span className="text-black">{p.name}</span></span>
                                                                <a href={p.url} className="text-uppercase text-gray float-right">Website</a>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </Link>
                                            </Col>
                                        )
                                    })}
                                </Row>
                               
                            </div>
                            </div>
                        </div>      
                        </div>
                    </div>
                    </div>
            </StyleRoot>
        )
    }
}

export default connect(mapStateToProps)(withStyles(styles)(portfolio))