import React from 'react'
import withStyles from 'react-jss'
import {connect} from 'react-redux'
import {StyleRoot} from 'radium'
import {Row, Col, FormGroup, Input, Container} from 'reactstrap'
import {saveName} from '../../../redux/actions/quote/index'

const styles  = {
    section:{
        padding: '15px !important'
    },
    formLabel: {
        color: 'black',
        fontWeight: '600',
        fontSize: '1.2em'
    }
}

const mapStateToProps = state => {
    return state
}

const mapDispatchToProps = dispatch => ({
    saveName: name => dispatch(saveName(name))
})

const AnimationStyles = require('../../../utils/animations');

class name extends React.Component{


    constructor(props){
        super(props);
        this.state = {
            animations: {},
            shouldRender: this.props.isMounted
        }
       
    }

    componentWillMount(){
        this.mountAnimations();
    }

    componentWillUnmount(){
        this.unmountAnimations();
    }

    componentDidUpdate(prevProps) {
        if (prevProps.isMounted && !this.props.isMounted) {
          setTimeout(
            () => this.setState({ shouldRender: false }),
            this.props.delayTime
          );
        } else if (!prevProps.isMounted && this.props.isMounted) {
          this.setState({ shouldRender: true });
        }
      }

    handleChange = (event) => {
        this.props.saveName(event.currentTarget.value)
    }

   
    mountAnimations = () => {
        
        this.setState({animations: AnimationStyles.styles.zoom_in_down})
    }

    unmountAnimations = () => {
        this.setState({
            animations: {}
        }, ()=>{
            this.setState({animations: AnimationStyles.styles.bounce_out_up})
        })
        
    }

    render(){
        const {classes} = this.props;
       
        return(
                <StyleRoot>
                    <Container>
                                <section style={this.state.animations} className={classes.section}>
                                    <Row>
                                        <Col xs={12} md={12}>
                                        
                                                <FormGroup className={classes.nameInputFormGroup}>                                                
                                                        <div className="form-group mb-0">
                                                            <Input type="text" className="form-control position-relative subscription-email  mb-0" id='name' value={this.props.QuoteReducer.name} onChange={this.handleChange} placeholder='Enter your name' />
                                                        </div>
                                                </FormGroup> 
                                        </Col>
                                    </Row> 
                                </section>
                            </Container>
                </StyleRoot>
        )
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(withStyles(styles)(name))