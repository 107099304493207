import React from 'react';
import {render, hydrate} from 'react-dom';
import './index.css';
import App from './App';
import * as serviceWorker from './serviceWorker';
//Pollyfill allows old browsers (IE) to utilize ES6 Features
import 'react-app-polyfill/ie11'
import 'react-app-polyfill/stable';



//redux imports
import { Provider } from 'react-redux'
import {store} from './redux/store/index'
import {persistor} from './redux/store/index'
import { PersistGate } from 'redux-persist/integration/react'



/*
render(
    
    <Provider store={store}>
        <PersistGate loading={<div>Loading...</div>} persistor={persistor}>
       
            <App />
        </PersistGate>
    </Provider>, document.getElementById('root'));
*/


const rootElement = document.getElementById("root");
if (rootElement.hasChildNodes()) {
  hydrate(
    <Provider store={store}>
        <PersistGate loading={<div>Loading...</div>} persistor={persistor}>
            <App />
        </PersistGate>
    </Provider>
  , rootElement);
} else {
  render(
    <Provider store={store}>
        <PersistGate loading={<div>Loading...</div>} persistor={persistor}>
            <App />
        </PersistGate>
    </Provider> ,rootElement
   );
  }


// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
