import {
    SAVE_NAME, SAVE_EMAIL, SAVE_PHONE, SAVE_HAS_WEBSITE, SAVE_WEBSITE, 
    SAVE_SERVICE, SAVE_FORM_SERVICES, SAVE_FORM_SERVICE_STATUS, REMOVE_SERVICE,
    SAVE_DETAILS, NEXT_FORM_STEP, PREV_FORM_STEP
} 
from '../../constants/quote/index'

export const saveName = name => ({
    type: SAVE_NAME,
    name
})

export const saveEmail = email => ({
    type: SAVE_EMAIL,
    email
})

export const savePhone = phone => ({
    type: SAVE_PHONE,
    phone
})

export const saveHasWebsite = data => ({
    type: SAVE_HAS_WEBSITE,
    data
})

export const saveWebsite = website => ({
    type: SAVE_WEBSITE,
    website
})

export const saveService = service => ({
    type: SAVE_SERVICE,
    service
})

export const removeService = service => ({
    type: REMOVE_SERVICE,
    service
})

export const saveFormServices = formServices => ({
    type: SAVE_FORM_SERVICES,
    formServices
})

export const saveFormServiceStatus = status => ({
    type: SAVE_FORM_SERVICE_STATUS,
    status
})

export const saveDetails = details => ({
    type: SAVE_DETAILS,
    details
})

export const nextFormStep = () => ({
    type: NEXT_FORM_STEP
})

export const prevFormStep = () => ({
    type: PREV_FORM_STEP
})