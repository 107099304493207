import {SAVE_SERVICE_DATA, SAVE_CURRENT_SERVICE_DATA} from '../../constants/service/index'

export const saveServiceData = data => ({
    type: SAVE_SERVICE_DATA,
    data
})

export const saveCurrentServiceData = data => ({
    type: SAVE_CURRENT_SERVICE_DATA,
    data
})