import React from 'react'
import {StyleRoot} from 'radium'
import LoadingImage from '../../../assets/images/loading/loader.gif'
import withStyles from 'react-jss'
import {connect} from 'react-redux'

const styles = {
    text: {
        textAlign: 'center'
    }
}

const mapStateToProps = state =>{
    return state
}


class loading extends React.Component{
    render(){
        var AnimationStyles = require('../../../utils/animations');
        const {classes} = this.props;
        return(
            <StyleRoot>
                {this.props.LoadingReducer.loading ?
                    <div id="loading">
                        <div id="loading-center">
                            <img src={LoadingImage} alt="Loading Animation"/>
                        </div>
                        <h3 style={AnimationStyles.styles.bounce_in_down} className={classes.text}>CLICKFICKS SOFTWARE <br/>&amp;<br/>MARKETING</h3>
                    </div> : <div/>
                }
            </StyleRoot>
        )
    }
}

export default connect(mapStateToProps)(withStyles(styles)(loading))