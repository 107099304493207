export const SAVE_NAME = "SAVE_NAME"
export const SAVE_EMAIL = "SAVE_EMAIL"
export const SAVE_PHONE = "SAVE_PHONE"
export const SAVE_HAS_WEBSITE = "SAVE_HAS_WEBSITE"
export const SAVE_WEBSITE = 'SAVE_WEBSITE'
export const SAVE_SERVICE = 'SAVE_SERVICE'
export const REMOVE_SERVICE = 'REMOVE_SERVICE'
export const SAVE_FORM_SERVICES = "SAVE_FORM_SERVICES"
export const SAVE_FORM_SERVICE_STATUS = 'SET_FORM_SERVICE_STATUS'
export const SAVE_DETAILS = 'SAVE_DETAILS'
export const NEXT_FORM_STEP = "NEXT_FORM_STEP"
export const PREV_FORM_STEP = "PREV_FORM_STEP"