import React from 'react'
import {withRouter} from 'react-router-dom'
import {Route, Switch} from 'react-router-dom'
//Page imports
import Home from './pages/home'
import Service from './pages/service'
import Portfolio from './pages/portfolio'
import PortfolioDetail from './pages/portfolioDetail'
import AboutUs from './pages/aboutUs'
import ContactUs from './pages/contactUs'
import Blog from './pages/blog'
import BlogDetail from './pages/blogDetail'
import FAQ from './pages/faq'
import Quote from './pages/quote'
import FourZeroFour from './pages/fourZeroFour'
import ThankYou from './pages/thankYou.js'
import PrivacyPolicy from './pages/privacyPolicy'

const Routes = props => {
    return (
        
            <Switch >
                    <Route exact path='/' component={Home}/>
                    <Route path='/service/:slug' component={Service}/>
                    <Route exact path='/portfolio' component={Portfolio}/>
                    <Route path='/portfolio/:slug' component={PortfolioDetail}/>
                    <Route path='/about-us' component={AboutUs}/>
                    <Route path='/contact-us' component={ContactUs}/>
                    <Route path='/blog' exact component={Blog}/>
                    <Route path='/blog/:slug' component={BlogDetail}/>
                    <Route path='/faq' component={FAQ}/>
                    <Route path='/quote' component={Quote}/>
                    <Route path='/thank-you' component={ThankYou}/>
                    <Route path='/404' component={FourZeroFour}/>
                    <Route path='/privacy-policy' component={PrivacyPolicy}/>
                    <Route component={FourZeroFour}/>
            </Switch>
        
    )
}
        
   

export default withRouter(Routes)